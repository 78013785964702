//Import in all required hooks and dependencies
//Import all bootstrap components
import Carousel from 'react-bootstrap/Carousel';
//Import in all media
import headerVideo from '../../Assets/Videos/servicesPageLandingVideo.mp4';

//Import reused components
import VideoSection    from '../ReusedSections/VideoSection';
import ScheduleSection from '../ReusedSections/ScheduleSection'



export default function Services() {
  const videoInformation = {
    title: 'RELATIONAL METHOD',
    subtitle: 'Cocreating a path to relating, one session at a time ',
    description: 'Offering supervision sessions to collaboratively build clinical and professional strengths ',
    video: headerVideo
  }


  return (
    <div className="App">
      <VideoSection data ={videoInformation}/>
      <div className='textAndCarousel' id="supervision">
        <div className='carouselHalfWidth'>
        <Carousel className='halfCarousel'>
                <Carousel.Item>
                    <img
                    src="https://images.pexels.com/photos/7176319/pexels-photo-7176319.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    src="https://images.pexels.com/photos/4098167/pexels-photo-4098167.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    src="https://images.pexels.com/photos/7176300/pexels-photo-7176300.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    />
                </Carousel.Item>
            </Carousel>
        </div>
        <div className='textHalfWidth'>
          <h2>Systemic Supervision</h2>
          <div className='text'>
          <p>
            <span className='tab'></span>
            My goal for supervision is to create a space that empowers you, as a therapist, within your clinical and professional strengths while fostering growth through reported challenges. I want to ensure that clinicians are working toward developing their theory of change along with feeling competent and capable in their skills.

          </p>
          <p>
            <span className='tab'></span>
            I’m currently a supervisor in training through the American Association of Marriage and Family Therapy (AAMFT). I am able to provide systemic supervision to master’s level interns, associate licensed clinicians, and fully licensed clinicians in Illinois. I’m also able to sign for AAMFT approved supervision hours for those applying for their associate or full clinical license in Marriage and Family Therapy.
          </p>
          <p>
            <span className='tab'></span>
            Systemic supervision allows clinicians the opportunity to receive support, guidance, and feedback on their relational cases. Relational cases include individual, couple, or family therapy clients who are seeking healing through their connections. These connections are ones that people have within themselves, with others, and within their communities. 

          </p>
          <p>
            <span className='tab'></span>
            Systemic supervision differs from other types of supervision. Clinicians are encouraged to conceptualize, assess, and intervene using systems theory based models. This theory emphasizes that change is facilitated by considering the interconnectedness between each individual, their relationships, and the group as a whole. 

          </p>
          <p>
            <span className='tab'></span>
            My style of supervision is compassionate, experiential, and collaborative. As supervisees discuss their clinical cases, I shift into four different roles depending on reported needs. These roles include a teacher, coach, mentor, and administrator. I want to make sure that my supervisees have as much autonomy as possible while they clinically practice, although I do provide directive feedback when ethical and legal matters are discussed during our time together. I want to make sure that a clinician’s goals for supervision are the focus for each time we meet. If there happens to be an impasse in our time working together, I will do what I can to repair the situation and utilize preventative measures  to ensure it does not happen again.


          </p>
          <p>
            <span className='tab'></span>
            I offer a free 60 minute initial appointment in person at my office or virtually. During this appointment you can expect to learn more about the supervision process, my approach to supervision, and get any of your questions answered. I am also interested in being able to learn more about you to see if we will be a good fit. I complete these meetings so that safety and trust can start to be developed in our relationship.  If we mutually agree to work together, we will co-create goals that best support your learning and growth. 

          </p>
          <p>
            <span className='tab'></span>
            Subsequent supervision sessions typically last an hour, and they can be completed in person at my office or virtually. My fee per session is $100 per hour. The frequency of meetings is determined by the reported need of the supervisee to accomplish their reported goals. I offer free 15 minute phone consultations as needed for my supervisees between our scheduled meetings. 
          </p>
          <p>
            <span className='tab'></span>
            If you are interested in becoming one of my supervisees, please reach out to me via email (ashleyu@relationalmethod.com) or give me a call (708-918-4293).  I look forward to seeing if I would be the right fit for helping you in your professional and clinical journey.
          </p>

          </div>
        </div>
      </div>
      <ScheduleSection />
    </div>
  );
};
  